<template>
  <div class="w-100 clearfix">
    <div class="container-fluid py-5">
      <div class="row">
        <div class="container">
          <div class="row">
            <div class="container py-5 text-center text-white" v-if="state==='loading'">
              <div-loader />
            </div>
            <div class="container py-5 text-center" v-else-if="state==='error'">
              <error-div :message="stateMessage" />
            </div>
            <div class="col-12 col-lg-6 pb-3 offset-lg-3 bg-white rounded-lg" v-else>
              <div class="w-100 border-bottom py-2 mb-2">
                <b>{{room.number}}</b>
              </div>
              <div class="col-12" v-if="offer.haveOffer">
                <span class="offer-tag on">{{offer.name}}</span>
                <span class="offer-tag ov">{{offer.value}}% OFF</span>
              </div>
              <div class="col-12">
                <b-img-lazy :src="room.imageUrl" class="w-100" />
              </div>
              <div class="form-row">
                <div class="col-12 py-2">
                  <h5>{{$t("selectRoomPackage")}}</h5>
                </div>
                <div class="col-12 col-sm-6 mb-2" v-for="(pack,index) in packages" :key="'package'+index">
                  <div class="w-100 h-100 border cursor roomPackages rounded-lg p-2"
                       @click="onPackageSelect(pack)"
                       :class="(selectedPackage===pack.id) ? 'selected' : ''">
                    <span><b>{{pack.name}}</b></span>
                    <div class="w-100"></div>
                    <ul>
                      <li>{{pack.ticketName}}</li>
                      <li v-for="(cat,ind) in pack.categories" :key="'packCatArt'+index+ind">
                        1 x {{cat.name}}
                      </li>
                      <li v-for="(packArt,ind) in pack.articles" :key="'packArt'+index+ind">
                        1 x {{packArt.n}}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div class="col-12 px-1">
                <room-blocks :is-day-closed="isDayClosed" :slot-timing="slotTiming"
                             @makeSelect="setSlot"
                             :extra-price="selectedPackagePrice"
                             :selected="true"
                             :date="date"
                             :room="room"
                             :current-time="currentTime"
                             :selected-slot="selectedSlots"/>
              </div>

              <div class="form-row">
                <div class="col-auto py-2">
                  <div class="form-row border rounded-lg">
                    <div @click="decPerson" class="cursor px-3 py-2 col-auto border-right">-</div>
                    <div class="px-3 py-2 col">{{person}} {{$t('persons')}}</div>
                    <div @click="incPerson" class="cursor px-3 py-2 col-auto border-left">+</div>
                  </div>
                </div>
              </div>

              <div class="form-row" v-if="articles.length>0">
                <div class="w-100 border rounded-lg">
                  <div class="col-12 py-2 border-bottom mb-2">
                    <b>{{$t('addOnArticles')}}</b>
                  </div>
                  <div class="col-12">
                    <div class="form-row">
                      <div class="col-12 col-sm-4 text-center mb-2"
                           v-for="(article,index) in articles" :key="'artRow'+index">
                        <div class="cursor border rounded-lg h-100 p-2"
                             @click="setArticle(article)"
                        :class="isArticleSelected(article.id) ? 'border border-success' : ''">
                          <div class="py-2 my-auto">
                            <b-icon-check-circle-fill variant="success" font-scale="1.5"
                                                      v-if="isArticleSelected(article.id)"/>
                            <b-icon-check-circle style="color: #C1C1C1" v-else font-scale="1.5" />
                          </div>
                          <img :src="article.imageUrl" class="pb-1" /> <br>
                          <b>{{article.name}}</b>
                          <div class="w-100"></div>
                          <small>{{article.info}}</small>
                          <div class="w-100"></div>
                          <b class="text-danger">{{$helper.convertToCurrency(calcArticlePrice(article))}}</b>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="form-row mt-3">
                <div class="w-100 border rounded-lg">
                  <div class="col-12 py-2">
                    <b>{{$t('summary')}}</b>
                  </div>

                  <div class="col-12 border-top">
                    <div class="form-row">
                      <div class="col py-2 my-auto ">
                        {{$t('roomPrice')}}
                      </div>
                      <div class="col-auto py-2 my-auto">
                        {{$helper.convertToCurrency(roomPrice)}}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 border-top">
                    <div class="form-row">
                      <div class="col py-2 my-auto ">
                        {{$t('articlePrice')}}
                      </div>
                      <div class="col-auto py-2 my-auto">
                        {{$helper.convertToCurrency(articlePrice)}}
                      </div>
                    </div>
                  </div>
                  <div class="col-12 border-top" v-if="offer.haveOffer">
                    <div class="form-row">
                      <div class="col py-2 my-auto ">
                        {{offer.name}} {{offer.value}}%
                      </div>
                      <div class="col-auto py-2 my-auto">
                        - {{$helper.convertToCurrency($helper.discountAmount(articlePrice+roomPrice,offer))}}
<!--                        - {{$helper.convertToCurrency(articlePrice+roomPrice-realVoucherAmount)}}-->
                      </div>
                    </div>
                  </div>

                  <div class="col-12 border-top text-danger" v-if="realVoucherAmount>0">
                    <div class="form-row">
                      <div class="col py-2 my-auto ">
                        {{$t('voucher')}}
                      </div>
                      <div class="col-auto py-2 my-auto">
                        - {{$helper.convertToCurrency(realVoucherAmount)}}
                      </div>
                    </div>
                  </div>


                  <div class="col-12 border-top">
                    <div class="form-row">
                      <div class="col py-2 my-auto ">
                        {{$t('total')}}
                      </div>
                      <div class="col-auto py-2 my-auto">
                        {{$helper.convertToCurrency($helper.afterOfferDiscount(articlePrice+roomPrice,offer)-realVoucherAmount)}}
                      </div>
                    </div>
                  </div>


                  <div class="col-12 border-top d-none">
                    <div class="form-row">
                      <div class="col my-auto py-2">
                        {{$t('applyVoucher')}}
                      </div>
                      <div class="col-auto py-2">
                        <b-input-group>
                          <b-form-input
                              style="text-transform: uppercase;"
                              :disabled="voucher.applied || voucher.state==='loading'"
                              v-model="voucher.code" autocomplete="off" />
                          <template #append v-if="voucher.applied">
                            <b-input-group-text >{{$helper.convertToCurrency(realVoucherAmount)}}</b-input-group-text>
                          </template>

                        </b-input-group>
                        <span class="text-danger">{{voucher.message}}</span>
                      </div>
                      <div class="col-auto py-2">
                        <b-button variant="danger" @click="removeVoucher"
                                  v-if="voucher.applied">
                          <b-icon-trash />
                        </b-button>
                        <b-button v-else
                                  @click="applyVoucher"
                                  :disabled="voucher.state==='loading'">
                          <b-spinner v-if="voucher.state==='loading'" small class="float-left mt-1 mr-2" />
                          {{$t('apply')}}
                        </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-row mt-4">
                <b-form-group class="col-12">
                  <b-form-checkbox name="agreeAgb"
                                   v-model="agree.agb"
                                   value="1" unchecked-value="0">
                    <span v-html="$t('agreeOurAgb')"></span>
                  </b-form-checkbox>
                </b-form-group>
                <b-form-group class="col-12" v-if="checkCovid">
                  <b-form-checkbox name="agreeCorona" value="1" unchecked-value="0"
                                   v-model="agree.covid">
                    <span v-html="$t('agreeOurCovidRegulation')"></span>
                  </b-form-checkbox>
                </b-form-group>
              </div>

              <div class="form-row mt-3">
                <div class="w-100" v-if="savingError">
                  <b-alert show variant="danger">{{errorMessage}}</b-alert>
                </div>

                <div class="w-100 mt-2 text-right">
                  <b-button variant="yellow" :disabled="saving" @click="startBooking" class="btn-lg">
                    <b-spinner v-if="saving" class="float-left mr-2 mt-2" small />
                    {{$t('bookNow')}} <b-icon-arrow-right-circle-fill font-scale="0.99" style="padding-bottom: 3px;" />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import ErrorDiv from "@/components/Template/ErrorDiv";
import DivLoader from "@/components/Template/DivLoader";
import RoomBlocks from "@/components/RoomBooking/RoomBlocks";
export default {
  name: "BookRoom",
  components: {RoomBlocks, DivLoader, ErrorDiv},
  computed : {
    realVoucherAmount() {
      const totalPrice = this.$helper.afterOfferDiscount(this.roomPrice+this.articlePrice,this.offer);
      return (this.voucherAmount>totalPrice) ? totalPrice : this.voucherAmount;
    }
  },
  created() {
    this.loadRoomData();
  },
  methods : {
    removeVoucher() {
      this.voucherCode = "";
      this.voucherAmount = 0;
      this.voucher.applied = false;
      this.voucher.code = "";
    },
    async applyVoucher() {
      this.voucherCode = "";
      this.voucherAmount = 0;
      this.voucher.state = 'loading';
      this.voucher.message = "";
      this.voucher.applied = false;
      const validate = await this.$parent.checkVoucher(this.voucher.code);
      if(validate.status) {
        this.voucher.state = 'success';
        this.voucherAmount = validate.amount;
        this.voucherCode = this.voucher.code;
        this.voucher.applied = true;
      } else {
        this.voucher.state = 'error';
        this.voucher.message = validate.message;
      }
    },
    onPackageSelect(pack) {
      if(this.selectedPackage!==pack.id) {
        this.selectedPackage = pack.id;
        this.selectedPackagePrice = parseFloat(pack.price);
        this.ticketId = pack.ticketId;
      }
      this.calculatePrice();
    },
    startBooking() {
      if(this.selectedSlots.length===0) {
        this.savingError = true;
        this.errorMessage = this.$t("selectMinimumOneTimeSlot");
      } else if(this.agree.agb==='0') {
        this.savingError = true;
        this.errorMessage = this.$t("pleaseAgreeAgb");
      } else if(this.agree.covid==='0' && this.checkCovid) {
        this.savingError = true;
        this.errorMessage = this.$t("pleaseAgreeCovidRegulation");
      } else {
        this.savingError = false;
        const token = localStorage.getItem("FpCusAuthToken");
        if(token!==null && token!=='') {
          this.saving = true;
          this.loading = true;
          this.$http.post("front/validateToken").then(response=>{
            if(response.data.error || response.data.error===undefined) {
              this.saving = false;
              this.openNextStepAfterCustomerCheckFail();
            } else {
              this.createBooking();
            }
          }).catch(()=>{
            this.saving = false;
            this.savingError = true;
            this.errorMessage = this.$t("errors.unExpectedError");
          });
        } else {
          this.openNextStepAfterCustomerCheckFail();
        }
      }
    },
    openNextStepAfterCustomerCheckFail() {
      this.$store.commit("setLogout");
      this.$bvModal.show("loginModal");
    },
    createBooking() {
      this.saving = true;
      this.$http.post("front/newRoomBooking",{
        offer : this.offer,
        voucherCode : this.voucherCode,
        voucherAmount : this.voucherAmount,
        date : this.date,
        package : this.selectedPackage,
        roomId : this.room.roomId,
        ticketId : this.ticketId,
        slots : this.selectedSlots,
        totalPerson : this.totalPerson,
        articles : this.selectedArticles,
        roomPrice : this.roomPrice,
        articlePrice : this.articlePrice
      }).then(response=>{
            if(response.data.error===undefined) {
              this.saving = false;
              this.savingError = true;
              this.errorMessage = this.$t("errors.unExpectedError");
            } else if(response.data.error) {
              this.saving = false;
              this.savingError = true;
              this.errorMessage = response.data.message;
            } else {
              this.haveError = false;
              window.location = response.data.url;
            }
          }).catch(error=>{
        this.saving = false;
        this.savingError = true;
        this.errorMessage = error.toString();
      });
    },
    calcArticlePrice(article) {
      const bookBy = parseInt(article.bookBy);
      const price = parseFloat(article.price);
      let finalPrice = price;
      if(bookBy===2) {
        finalPrice = price;
      } else if(bookBy===3) {
        finalPrice = price*this.totalPerson;
      } else if(bookBy===4) {
        finalPrice = price*this.totalSlot;
      }
      finalPrice = (finalPrice>0) ? finalPrice : price;
      return finalPrice;
    },
    calculatePrice() {
      this.totalSlot = this.selectedSlots.length
      this.totalPerson = this.totalSlot*this.person;
      const pricePerPerson = parseFloat(this.room.price)+this.selectedPackagePrice;
      this.roomPrice = this.totalPerson*pricePerPerson;
      this.articlePrice = 0;
      const keys = Object.keys(this.selectedArticles);
      keys.forEach((key) => {
        const obj = this.selectedArticles[key];
        const newPrice = this.calcArticlePrice(obj);
        this.selectedArticles[key].finalPrice = newPrice;
        this.articlePrice += newPrice;
      });
    },
    decPerson() {
      if(this.person>this.room.minPerson) {
        this.person--;
        this.calculatePrice();
      }
    },
    incPerson() {
      if(this.person<this.room.maxPerson) {
        this.person++;
        this.calculatePrice();
      }
    },
    isArticleSelected(id) {
      const index = this.selectedArticleId.indexOf(id);
      return (index!==-1);
    },
    setArticle(article) {
      const id = article.id;
      const index = this.selectedArticleId.indexOf(id);
      if(index===-1) {
        this.selectedArticleId.push(id);
        const pushArticle = {
          id : article.id,
          name : article.name,
          bookBy : parseInt(article.bookBy),
          price : parseFloat(article.price),
          finalPrice : parseFloat(article.price),
          tax : article.tax
        };
        this.selectedArticles.push(pushArticle);
      } else {
        this.selectedArticleId.splice(index,1);
        this.selectedArticles.splice(index,1);
      }
      this.calculatePrice();
    },
    setSlot(room,slotOpen,slotNumber) {
      const open = room.open;
      const thisTime = this.currentTime;
      let time = 0;
      if(slotNumber==="1") {
        time = 630;
      } else if(slotNumber==="2") {
        time = 840;
      } else {
        time = 1020;
      }
      if(open===1 && slotOpen===1 && time>thisTime && !this.isDayClosed.close) {
        const index = this.selectedSlots.indexOf(slotNumber);
        if(index===-1) {
          this.selectedSlots.push(slotNumber);
        } else {
          this.selectedSlots.splice(index,1);
        }
        this.calculatePrice();
      }
    },
    loadRoomData() {
      if(this.date===undefined || this.roomId===undefined) {
        this.state = "error";
        this.stateMessage = this.$t("errors.unExpectedError");
      } else {
        this.state = "loading";
        this.$http.get("front/roomDetail",{
          params : {
            roomId : this.roomId,
            date : this.date
          }
        }).then(response=>{
          if(response.data.error===undefined) {
            this.state = "error";
            this.stateMessage = this.$t("errors.unExpectedError");
          } else if(response.data.error) {
            this.state = "error";
            this.stateMessage = response.data.message;
          } else {
            this.selectedArticleId = [];
            this.selectedArticles = [];
            this.selectedSlots = [];
            this.totalSlot = 0;
            this.slotTiming = response.data.slotTiming;
            this.checkCovid = response.data.checkCovid;
            this.room = response.data.detail;
            this.currentTime = response.data.time;
            this.articles = response.data.articles;
            this.offer = response.data.offer;
            this.person = this.room.minPerson;
            this.totalPerson = this.person;
            this.isDayClosed = response.data.isDayClosed;
            this.packages = response.data.packages
            if(this.packages!=null) {
              this.packages.forEach((value, index) => {
                if(index === 0) {
                  this.selectedPackage = value.id
                  this.selectedPackagePrice = parseFloat(value.price)
                  this.ticketId = value.ticketId;
                }
              });
            }
            this.calculatePrice();
            this.state = "success";
          }
        }).catch(error=>{
          this.state = "error";
          this.stateMessage = error.toString();
        });
      }
    }
  },
  data() {
    return {
      slotTiming : {},
      checkCovid: true,
      agree: {
        agb: "0",
        covid: "0"
      },
      offer : {},
      voucher : {
        state : "",
        message : "",
        code : "",
        applied : false
      },
      selectedPackage : "",
      selectedPackagePrice : 0,
      packages: [],
      ticketId : "",
      // packages : [
      //   {
      //     id : "1",
      //     title : "ERLEBNIS",
      //     slogan : "FLIPPI'S PARTYPAKET",
      //     extraPrice : 0,
      //     articles : [
      //       { text : "3 Stunden Ticket und Geburtstagsraum für 2,5 Stunden" },
      //       { text : "Flippi Socken" },
      //       { text : "1x Portion Pommes" },
      //       { text : "1x Getränk 0,5 Liter" },
      //     ]
      //   },
      //   {
      //     id : "2",
      //     title : "EMOTION",
      //     slogan : "FLIPPI'S PARTYPAKET",
      //     extraPrice : 4,
      //     articles : [
      //       { text : "3 Stunden Ticket und Geburtstagsraum für 2,5 Stunden" },
      //       { text : "Flippi Socken" },
      //       { text : "1x Speise nach Wahl" },
      //       { text : "1x Getränk 0,5 Liter" },
      //       { text : "1x Slush 0,3 Liter" },
      //     ]
      //   }
      // ],
      currentTime : 0,
      form : {
        agree : '0'
      },
      isDayClosed : false,
      loading : false,
      saving : false,
      savingError : false,
      errorMessage : "",
      voucherAmount : 0,
      voucherCode : "",
      roomPrice : 0,
      articlePrice : 0,
      totalSlot : 0,
      totalPerson : 0, // including slot
      person : 0,
      date : this.$route.params.date,
      roomId : this.$route.params.code,
      state : 'loading',
      stateMessage : '',
      room : {},
      articles : [],
      selectedArticleId : [],
      selectedArticles : [],
      selectedSlots : [],
    }
  }
}
</script>
