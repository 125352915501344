<template>
  <div class="form-row">
    <div class="roomSlots mr-2 rounded-lg p-2 text-center"
         @click="setSelect(room,room.slotOne,'1')"
         v-if="isDayClosed.isHoliday"
         v-bind:class="$helper.roomClass(room,room.slotOne,'1',isDayClosed,currentTime,selectedSlot,selected)">
      <b>{{slotTiming['1']}}</b>
      <div class="w-100"></div>
      <span>{{$helper.convertToCurrency(parseFloat(room.price)+extraPrice)}} /<b-icon-person-fill /></span>
      <div class="w-100 text-center occupied">
        {{$t('occupied')}}
      </div>
    </div>
    <div class="roomSlots mr-2 rounded-lg p-2 text-center"
         @click="setSelect(room,room.slotTwo,'2')"
         v-bind:class="$helper.roomClass(room,room.slotTwo,'2',isDayClosed,currentTime,selectedSlot,selected)">
      <b>{{slotTiming['2']}}</b>
      <div class="w-100"></div>
      <span>{{$helper.convertToCurrency(parseFloat(room.price)+extraPrice)}} / <b-icon-person-fill /></span>
      <div class="w-100 text-center occupied">
        {{$t('occupied')}}
      </div>
    </div>

    <div class="roomSlots rounded-lg p-2 mr-2 text-center"
         @click="setSelect(room,room.slotThree,'3')"
         v-bind:class="$helper.roomClass(room,room.slotThree,'3',isDayClosed,currentTime,selectedSlot,selected)">
      <b>{{slotTiming['3']}}</b>
      <div class="w-100"></div>
      <span>{{$helper.convertToCurrency(parseFloat(room.price)+extraPrice)}} / <b-icon-person-fill /></span>
      <div class="w-100 text-center occupied">
        {{$t('occupied')}}
      </div>
    </div>

    <div class="col-12 pt-3">
      <router-link v-if="!selected" class="btn btn-yellow btn-lg align-self-center"
                   :to="'/bookRoom/'+room.code+'/'+date">
        {{$t("bookNow")}} <b-icon-arrow-right-circle-fill font-scale="0.99" style="padding-bottom: 3px;" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "RoomBlocks",
  props : {
    slotTiming : Object,
    isDayClosed : Object,
    room : Object,
    currentTime : Number,
    selectedSlot : Array,
    date : String,
    selected : Boolean,
    extraPrice : Number
  },

  methods : {
    setSelect(room,slotOpen,slotNumber) {
      if(this.selected) {
        this.$emit("makeSelect",room,slotOpen,slotNumber);
      }
    }
  }
}
</script>

<style scoped>

</style>
